import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: RouteComponent, ...rest }) => {
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const user = useSelector((state) => state.auth.userAuth);
    
    return (
        <Route
            {...rest}
            render={props => {
                // If user is authenticated and is an admin
                if (user && isAdmin) {
                    return <RouteComponent {...props} />;
                } else if (user) {
                    // If user is authenticated but not an admin, redirect to a specific route (e.g., /jobs)
                    return <Redirect to="/jobs" />;
                } else {
                    // If user is not authenticated, redirect to the login page (e.g., /signin)
                    return <Redirect to="/signin" />;
                }
            }}
        />
    );
};

export default AdminRoute;
