export const firebaseConfig = {
    apiKey: "AIzaSyCDw0KXt6Hlhi8Nm-zM_MQJSsvy0MtK48g",
    authDomain: "startups-7514e.firebaseapp.com",
    databaseURL: "https://startups-7514e-default-rtdb.firebaseio.com",
    projectId: "startups-7514e",
    storageBucket: "startups-7514e.appspot.com",
    messagingSenderId: "465964340987",
    appId: "1:465964340987:web:58e7e2bdaed231c916025e",
    measurementId: "G-3HVRS0SK7S"
  };

