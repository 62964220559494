import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../css/tailwind.config.js';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
}

export const parseStringInput = (input) =>{
  try {
    // Process each element if input is an array
    if (Array.isArray(input)) {
      return input.map(item => {
        // Check if the item is a string and needs parsing
        if (typeof item === 'string') {
          return JSON.parse(item);
        }
        // If the item is already an object, use it as is
        return item;
      });
    }

    // If the input is a string, process it directly
    const validJsonString = input.replace(/'/g, '"');
    const parsed = JSON.parse(validJsonString);

    // Further processing if parsed is an array of strings
    if (Array.isArray(parsed) && typeof parsed[0] === 'string') {
      return parsed.map(value => ({ value, label: value }));
    }

    // Return the parsed array as is
    return parsed;
  } catch (e) {
    console.error("Parsing error:", e);
    return []; // Return an empty array in case of error
  }
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);
