import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

async function fetchUserDataFromAPI(token) {
  if (!token) {
    console.error('No token available');
    return;
  }

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `jwt ${token}`,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/user/`, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error){
    console.error('Error fetching user data:', error);
    throw error; // Re-throw the error for handling in the thunk
  }
}

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (token, { rejectWithValue }) => {
    try {
      // Replace this with the actual API call
      const response = await fetchUserDataFromAPI(token); 
      if (!response) {
        throw new Error('Error fetching user data');
      }
      return await response; // Parse the JSON response
    } catch (error) {
      console.log('error', error);
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      return rejectWithValue({ message: errorMessage, details: error.details || {} });
    }
  }
);

const initialState = {
  userAuth: null,
  isAdmin: false,
  premium: false,
  percentage: 0,
  loading: false,
  error: null,
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Reducers for your synchronous actions (if any)
    saveUser: (state, action) => {
      state.userAuth = action.payload;
    },
    saveAdmin:(state, action) => {
      state.isAdmin = action.payload;
    },
    savePremium:(state, action) => {
      state.premium = action.payload;
    },
    savePercentage:(state, action) => {
      state.percentage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        const userData = action.payload;
        userData.id = userData.userId;
        state.userAuth = userData;
        state.isAdmin = userData.admin;
        state.premium = userData.premium;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        console.error('Rejected action:', action);
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { saveUser, saveAdmin, savePremium, savePercentage } = authSlice.actions;

// Selectors
export const selectUser = (state) => state.auth.userAuth;
export const selectAdmin = (state) => state.auth.isAdmin;
export const selectPremium = (state) => state.auth.premium;
export const selectPercentage = (state) => state.auth.percentage;

export default authSlice.reducer;
