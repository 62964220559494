import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: RouteComponent, ...rest }) => {
  const user = useSelector((state) => state.auth.userAuth);
  const token = localStorage.getItem('token');

  const decodeJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Error decoding token: ", e);
      return null;
    }
  }

  const isTokenValid = () => {
    if (!token) return false;
    try {
      const decodedToken = decodeJWT(token);
      return !!decodedToken;
    } catch (error) {
      console.error("Error decoding token: ", error);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (user || isTokenValid()) {
          return <RouteComponent {...props} />;
        } else {
          sessionStorage.setItem('prevLocation', window.location.pathname);
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
