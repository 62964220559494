import React, { useEffect, lazy, Suspense } from "react";
import "./css/style.scss";

import "./charts/ChartjsConfig";
import { initializeApp } from "firebase/app";
import "firebase/analytics";
import { firebaseConfig } from "./config/firebase-config";
import { useDispatch } from "react-redux";
import {
  saveUser,
  saveAdmin,
  savePremium,
} from "./redux/slice/authSlice";
import ProtectedRoute from "./utils/ProtectedRoute";
import AdminRoute from "./utils/AdminRoute";
import { ChatProvider } from "./context/chatContext.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";

import { initializeAnalytics } from "firebase/analytics";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Analytics = lazy(() => import("./pages/Analytics"));
const JobListing = lazy(() => import("./pages/job/JobListing"));
const JobPost = lazy(() => import("./pages/job/JobPost"));
const JobApplications = lazy(() => import("./pages/job/JobApplications"));
const CompanyEdit = lazy(() => import("./pages/job/CompanyEdit"));
const CompanyProfile = lazy(() => import("./pages/job/CompanyProfile"));
const Profile = lazy(() => import("./pages/team/Profile"));
const TeamTabs = lazy(() => import("./pages/team/TeamTabs"));
const TeamTiles = lazy(() => import("./pages/TeamTiles"));
const UserProposals = lazy(() => import("./pages/UserProposals"));
const Account = lazy(() => import("./pages/settings/Account"));
const Notifications = lazy(() => import("./pages/settings/Notifications"));
const Billing = lazy(() => import("./pages/settings/Billing"));
const Plans = lazy(() => import("./pages/settings/Plans"));
const Messages = lazy(() => import("./pages/Messages"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const CreateJobs = lazy(() => import("./pages/CreateJob"));
const EditJobs = lazy(() => import("./pages/EditJobs.js"));
const Signin = lazy(() => import("./pages/Signin.js"));
const Signup = lazy(() => import("./pages/Signup.js"));
const SignupCompany = lazy(() => import("./pages/SignupCompany.js"));
const Jobs = lazy(() => import("./pages/Jobs.js"));
const SquadTabs = lazy(() => import("./pages/team/SquadTabs.js"));
const ResetPassword = lazy(() => import("./pages/ResetPassword.js"));
const PageNotFound = lazy(() => import("./pages/utility/PageNotFound.js"));
const UserViews = lazy(() => import("./pages/UserViews"));
const UserNotifications = lazy(() => import("./pages/UserNotifications"));
const QuizList = lazy(() => import("./pages/QuizList"));
const QuizPage = lazy(() => import("./pages/QuizPage"));
const PaymentSuccess = lazy(() => import("./pages/PaymentSuccess.js"));
const MatchDevJob = lazy(() => import("./pages/MatchDevJob.js"));
const MatchJobs = lazy(() => import("./pages/MatchJobs.js"));
const Inbox = lazy(() => import("./pages/Inbox.js"));
const Privacy = lazy(() => import("./pages/Privacy.js"));
const JoinPage = lazy(() => import("./pages/JoinPage.js"));
const Contact = lazy(() => import("./pages/Contact.js"));

function App() {
  const app = initializeApp(firebaseConfig);
  initializeAnalytics(app);

  const dispatch = useDispatch();

  const decodeJWT = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Error decoding token: ", e);
      return null;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const nonRedirectPaths = [
      "/signin",
      "/privacy",
      "/jobs",
      "/",
      "/sitemap.xml",
      "/robots.txt",
      "/ads.txt",
      "/join",
      "/signup-company",
    ];

    if (token) {
      try {
        const decodedToken = decodeJWT(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken && decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          if (!nonRedirectPaths.includes(window.location.pathname)) {
            window.location.href = "/signin";
          }
        } else if (decodedToken) {
          dispatch(saveUser(decodedToken));
          dispatch(saveAdmin(decodedToken?.admin));
          dispatch(savePremium(decodedToken?.premium));
        }
      } catch (error) {
        console.error("Error decoding token: ", error);
        localStorage.removeItem("token");
        if (!nonRedirectPaths.includes(window.location.pathname)) {
          window.location.href = "/signin";
        }
      }
    } else {
      if (!nonRedirectPaths.includes(window.location.pathname)) {
        window.location.href = "/signin";
      }
    }
  }, [dispatch]);

  return (
    <>
      <ChatProvider>
        <Router>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen">
                <FaSpinner className="animate-spin text-indigo-600 text-4xl" />
              </div>
            }
          >
            <Switch>
              <AdminRoute exact path="/admin/dashboard" component={Analytics} />
              <ProtectedRoute
                exact
                path="/user/dashboard"
                component={Analytics}
              />
              <Route exact path="/jobs">
                <JobListing />
              </Route>
              <ProtectedRoute exact path="/quizzes" component={QuizList} />
              <ProtectedRoute exact path="/job/job-post/" component={JobPost} />
              <ProtectedRoute
                exact
                path="/job/applications/"
                component={JobApplications}
              />
              <ProtectedRoute
                exact
                path="/company/edit/"
                component={CompanyEdit}
              />
              <ProtectedRoute
                exact
                path="/company/profile/"
                component={CompanyProfile}
              />
              <ProtectedRoute exact path="/team/profile/" component={Profile} />
              <ProtectedRoute exact path="/team/tabs" component={TeamTabs} />
              <ProtectedRoute exact path="/team/info" component={TeamTiles} />
              <ProtectedRoute
                exact
                path="/user/proposals"
                component={UserProposals}
              />
              <ProtectedRoute exact path="/user/views" component={UserViews} />
              <ProtectedRoute
                exact
                path="/user/notifications"
                component={UserNotifications}
              />
              <ProtectedRoute
                exact
                path="/settings/account"
                component={Account}
              />
              <ProtectedRoute
                exact
                path="/settings/notifications"
                component={Notifications}
              />
              <ProtectedRoute
                exact
                path="/settings/billing"
                component={Billing}
              />
              <ProtectedRoute exact path="/settings/plans" component={Plans} />
              <ProtectedRoute exact path="/messages" component={Messages} />
              <ProtectedRoute
                exact
                path="/match-dev-job"
                component={MatchDevJob}
              />
              <ProtectedRoute exact path="/match-jobs" component={MatchJobs} />
              <ProtectedRoute exact path="/inbox" component={Inbox} />
              <ProtectedRoute path="/quiz/:id" component={QuizPage} />
              <Route exact path="/">
                <LandingPage />
              </Route>

              <Route exact path="/join">
                <JoinPage />
              </Route>

              <ProtectedRoute exact path="/team/tabs" component={TeamTabs} />

              <Route exact path="/hiring/squad">
                <SquadTabs />
              </Route>

              <Route exact path="/contact">
                <Contact />
              </Route>

              <Route exact path="/privacy">
                <Privacy />
              </Route>

              <Route exact path="/payment/success">
                <PaymentSuccess />
              </Route>

              <Route exact path="/signin">
                <Signin />
              </Route>
              <Route exact path="/signup-developer">
                <Signup />
              </Route>
              <Route exact path="/signup-company">
                <SignupCompany />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <ProtectedRoute
                exact
                path="/jobs/create"
                component={CreateJobs}
              />
              <ProtectedRoute exact path="/jobs/edit" component={EditJobs} />
              <ProtectedRoute exact path="/jobs/me" component={Jobs} />
              <AdminRoute exact path="/hiring/proposals" component={Jobs} />
              <Route exact path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </Suspense>
        </Router>
        <ToastContainer />
      </ChatProvider>
    </>
  );
}

export default App;
