import React, { createContext, useState, useEffect } from 'react';
import { getDatabase, ref, push, onValue, set, update } from 'firebase/database';
import { selectUser } from "../redux/slice/authSlice";
import { useSelector } from 'react-redux';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {

  const user = useSelector(selectUser);

  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [userConversations, setUserConversations] = useState({});
  const [userTwo, setUserTwo] = useState({});
  const [userOne, setUserOne] = useState({});
  const db = getDatabase();

  useEffect(() => {
    const conversationsRef = ref(db, '/conversations');
    onValue(conversationsRef, (snapshot) => {
      if (snapshot.exists()) {
        const allConversations = snapshot.val();
        const filteredConversations = {};
  
        Object.keys(allConversations).forEach(key => {
          const conversation = allConversations[key];
          // Check if userOne and userTwo exist and then check their ids
          if (conversation.userOne && conversation.userTwo && 
              (conversation.userOne.id === user?.id || conversation.userTwo.id === user?.id)) {
            filteredConversations[key] = conversation;
          }
        });
  
        setUserConversations(filteredConversations);
      } else {
        setUserConversations({});
      }
    });
  }, [db, user]); // Ensure user is included in the dependencies array

  useEffect(() => {
    console.log("Current conversation ID:", currentConversationId);
    if (currentConversationId) {
      const conversationRef = ref(db, `/conversations/${currentConversationId}`);
      onValue(conversationRef, (snapshot) => {
        if (snapshot.exists()) {
          const conversationData = snapshot.val();
          setCurrentConversation(conversationData);
          if (conversationData.userOne.id === user.id) {
            setUserTwo(conversationData.userTwo); 
          } else {
            setUserTwo(conversationData.userOne); 
          }
        } else {
          setCurrentConversation(null);
          setUserTwo(null);
        }
      });
    } else {
      setCurrentConversation(null);
      setUserTwo(null);
    }
  }, [currentConversationId, db, user]);
  

  const markMessageAsRead = async (chatID, messageID) => {
    const isReadRef = ref(db, `/conversations/${chatID}/messages/${messageID}/isRead`);
    await set(isReadRef, true);
  };

  const addConversation = async (conversationData) => {
    const conversationsRef = ref(db, '/conversations');
  
    // Query the conversations to check for duplicates
    let duplicateFound = false;
    await onValue(conversationsRef, (snapshot) => {
      if (snapshot.exists()) {
        const conversations = snapshot.val();
        Object.values(conversations).forEach(conversation => {
          if ((conversation.userOne.id === conversationData.userOne.id && conversation.userTwo.id === conversationData.userTwo.id) ||
              (conversation.userOne.id === conversationData.userTwo.id && conversation.userTwo.id === conversationData.userOne.id)) {
            duplicateFound = true;
          }
        });
      }
    }, { onlyOnce: true }); // Ensures the onValue callback is only executed once
  
    // If a duplicate is found, return null or handle as desired
    if (duplicateFound) {
      console.log('Duplicate conversation found.');
      return null;
    }
  
    // If no duplicate, proceed to add the new conversation
    const newConversationRef = push(conversationsRef);
    await set(newConversationRef, conversationData);
    return newConversationRef.key;
  };
  


  const sendMessage = async ({message, messageFile, chatID, user}) => {
    const keyMessage = new Date().getTime().toString();
    const updates = {};
  
    try {
      let postData = {};
  
      if (messageFile) {
        // File size validation (4KB limit)
        if (messageFile.size > 2 * 1024 * 1024) {
          alert('File size exceeds the limit (2MB)');
          return;
        }
  
        // Prevent URLs from being sent in the message
        const containsUrl = /(http(s)?:\/\/[^\s]+)/gi.test(message);
        if (containsUrl) {
          alert('URLs are not allowed in messages');
          return;
        }
  
        // Convert file to Base64
        const reader = new FileReader();
        reader.readAsDataURL(messageFile);
        reader.onload = async () => {
          const base64String = reader.result;
  
          postData = {
            message: message,
            messageFile: base64String, // Base64 string of the image
            sentBy: user.id,
            dateCreated: new Date(),
            messageType: "file",
            isRead: false,
          };
          updates['/conversations/' + chatID + '/messages/' + keyMessage] = postData;
  
          // Update Firebase with the new message
          await update(ref(db), updates);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
      } else {
        postData = {
          message: message,
          messageFile: '',
          sentBy: user.id,
          dateCreated: new Date(),
          messageType: "text",
          isRead: false,
        };
        updates['/conversations/' + chatID + '/messages/' + keyMessage] = postData;
        await update(ref(db), updates);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const value = {
    setUserTwo,
    setUserOne,
    userOne,
    userTwo,
    currentConversationId,
    setCurrentConversationId,
    currentConversation,
    addConversation,
    markMessageAsRead,
    sendMessage,
    userConversations,
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};
